// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { docsearchConfig } from './config.ts'
import './static-site.scss' // our own style overrides

export async function initDocSearch() {
  const root = document.getElementById('tldocsearch-root')
  if (!root) return

  const { default: docsearch } = await import('@docsearch/js')

  // https://docsearch.algolia.com/docs/api
  // Using a type cast to resolve the type compatibility issue between different versions
  // of @docsearch packages
  docsearch({
    container: root,
    ...docsearchConfig,
  } as unknown as Parameters<typeof docsearch>[0])
}
