import { z } from 'zod'

export const colorSchema = z.enum([
  'berry',
  'coral',
  'cornflowerBlue',
  'emerald',
  'indigo',
  'mediumSpringGreen',
  'olivine',
  'orange',
  'orchid',
  'pink',
  'turquoise',
  'violet',
  'yellow',
])
