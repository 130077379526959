import { ready } from '../sharedWithConsole/ready.ts'

ready(() => {
  const nav = document.querySelector('.nav')
  if (!nav) return

  nav.addEventListener('click', (event) => {
    if (!(event.target instanceof HTMLElement)) return
    const target = event.target.closest('li')
    if (!target) return

    const subItems = target.querySelector('ul')
    nav.querySelectorAll('ul').forEach((ul) => {
      ul.style.display = 'none'
    })

    if (subItems) {
      subItems.style.display = 'block'
    } else if (target.closest('.nav .nav')) {
      const parent = target.parentElement
      if (parent) parent.style.display = 'block'
    }
  })
})
